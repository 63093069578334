import { useMutation, useQuery } from 'react-query'

import { RunbookComponent, RunbookTeam, RunbookVersion, StreamListStream, User } from './types'
import { apiClient_UNSTABLE } from 'main/services/api/api-client-unstable'
import { RunbookVersionCreateResponse } from '../api/data-providers/runbook-types'
import { ApiError } from '../api'

export type GetRunbookVersionResponse = {
  runbook_version: RunbookVersion
  meta: RunbookVersionMeta
}

export type RunbookVersionUser = Pick<
  User,
  | 'name'
  | 'first_name'
  | 'last_name'
  | 'id'
  | 'handle'
  | 'avatar'
  | 'online'
  | 'color'
  | 'archived'
  | 'status'
  | 'not_in_account'
  | 'is_admin'
  | 'subject_type'
  | 'has_role_on_runbook'
>

export type RunbookVersionPermissionsResponse = RunbookVersionMeta['permissions']['runbook_version']
export type StreamsPermissionsResponse = RunbookVersionMeta['permissions']['streams']
export type RunsPermissionsResponse = RunbookVersionMeta['permissions']['runs']
export type RunbookTeamsPermissionsResponse = RunbookVersionMeta['permissions']['runbook_teams']

export type RunbookVersionMeta = {
  permissions: {
    streams: {
      create: number[]
      create_tasks: number[]
    }
    runbook_teams: {
      create: number[]
    }
    runs: {
      create: number[]
      pause: number[]
      resume: number[]
      update: number[]
    }
    runbook_version: {
      import: number[]
      create: number[]
      adhoc_comms: number[]
      manage_template: number[]
      review: number[]
      destroy: number[]
    }
    teams: {
      update: number[]
    }
  }
  runbook_teams: RunbookTeam[]
  runbook_components: RunbookComponent[]
  users: RunbookVersionUser[]
  streams: StreamListStream[]
}

export type GetRunbookVersionsResponse = {
  runbook_versions: RunbookVersion[]
}

let controller: AbortController | undefined

export const getRunbookVersion = async (runbookId: string | number, runbookVersionId: string | number) => {
  if (controller) controller.abort()
  controller = new AbortController()
  const signal = controller.signal

  try {
    const { data } = await apiClient_UNSTABLE.get<GetRunbookVersionResponse>(
      `runbooks/${runbookId}/runbook_versions/${runbookVersionId}`,
      {
        signal
      }
    )
    return data
  } catch (error: any) {
    // TODO: handle any errors
    throw error
  }
}

export const getRunbookVersions = async (runbookId: string | number) => {
  try {
    const { data } = await apiClient_UNSTABLE.get<GetRunbookVersionsResponse>(`runbooks/${runbookId}/runbook_versions`)
    return data
  } catch (error: any) {
    // TODO: handle any errors
    throw error
  }
}

export const useGetRunbookVersions = (runbookId: number) => {
  return useQuery<RunbookVersion[], ApiError>(['runbook-versions', runbookId], async () => {
    const data = await getRunbookVersions(runbookId)
    return data.runbook_versions
  })
}

export const createNewRunbookVersion = async (runbookId: string | number, payload: { base_version_id: number }) => {
  const { data } = await apiClient_UNSTABLE.post<RunbookVersionCreateResponse>(
    `runbooks/${runbookId}/runbook_versions`,
    payload
  )
  return data
}

export const useCreateNewRunbookVersion = (runbookId: number) => {
  return useMutation<RunbookVersionCreateResponse, ApiError, { base_version_id: number }>(
    'createRunbookVersion',
    async data => {
      return createNewRunbookVersion(runbookId, data)
    }
  )
}

export const convertRunbookVersionToTemplate = async (
  runbookId: string | number,
  runbookVersionId: string | number
) => {
  const { data } = await apiClient_UNSTABLE.post(
    `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/convert_to_template`
  )
  return data
}
